import { MELIDATA_DEFAULTS } from './constants';

const {
  NONE,
  PATH_EVENT,
  PATH_SHOW,
  NO_APPLY,
  STREAM_NAME,
  TRACK_TYPE: { EVENT },
  MODE: { CLEAN_SEND },
  VALID_PATH_CONVENTION_REGEX,
} = MELIDATA_DEFAULTS;

const getCalmEventDataProps = (calmViewTrack, calmTrackdata) => {
  const {
    type,
    sectionId,
    sectionVersionId,
    element: triggerElement,
    referer,
    conversion = false,
  } = calmTrackdata;

  return {
    ...calmViewTrack.event_data,
    module_referer: referer || NO_APPLY,
    section_name: type || NONE,
    section_id: sectionId ? String(sectionId) : NONE,
    section_version_id: sectionVersionId ? String(sectionVersionId) : NONE,
    conversion,
    element: triggerElement || NONE,
  };
};

const handleCalmShowTrack = (
  calmViewTrack,
  calmTrackdata,
  experiments,
  melidataFn,
) => {
  const trackData = {};
  const eventData = getCalmEventDataProps(calmViewTrack, calmTrackdata);

  melidataFn('createShowTrack', trackData);
  melidataFn('withPath', PATH_SHOW, trackData);
  melidataFn('withStream', STREAM_NAME, trackData);
  melidataFn('withData', eventData || {}, trackData);

  if (experiments) {
    melidataFn('withExperiments', experiments, trackData);
  }

  melidataFn('sendTrack', trackData);
};

const handleCalmTrackMelidata = (calmViewTrack, calmTrackdata, melidataFn) => {
  const data = {
    ...calmViewTrack,
    path: PATH_EVENT,
    event_data: getCalmEventDataProps(calmViewTrack, calmTrackdata),
  };

  melidataFn(CLEAN_SEND, EVENT, data);
};

const handleCustomEventTrackMelidata = (
  validPathConvention,
  melidataInformation,
  melidataFn,
) => {
  const {
    calmEventMelidata,
    calmViewTrack,
    customEventMelidata,
    customViewTrack,
  } = melidataInformation;
  const {
    experiments,
    path: customPath = '',
    event_data: customEventData = {},
  } = customViewTrack || calmViewTrack;

  const { reply_data: replyData, ...melidata } = customEventMelidata;
  const isInheritingPath = melidata.path.startsWith(customPath);

  const track = {
    ...melidata,
    ...(experiments ? { experiments } : {}),
  };

  if (validPathConvention) {
    track.event_data = {
      ...track.event_data,
      ...getCalmEventDataProps(calmViewTrack, calmEventMelidata),
    };
  }

  if (replyData && isInheritingPath && Object.keys(customEventData).length) {
    track.event_data = {
      ...track.event_data,
      ...customEventData,
    };
  }

  melidataFn(CLEAN_SEND, EVENT, track);
};

export const handleEventTrackMelidata = (customTrackdata, calmTrackdata) => {
  const { melidata: melidataFn, __PRELOADED_STATE__: state = {} } =
    window || {};

  if (melidataFn) {
    const viewTrackData = state.landingConfig?.tracking?.melidata || {};
    const { customTrack: customViewTrack, calmTrack: calmViewTrack } =
      viewTrackData;

    const { melidata: customMelidata = {} } = customTrackdata || {};
    const hasCustomMelidata = !!Object.keys(customMelidata || {}).length;

    const validPathConvention = VALID_PATH_CONVENTION_REGEX.test(
      customMelidata?.path,
    );

    // If valid path convention skip calm track
    if (!validPathConvention) {
      handleCalmTrackMelidata(calmViewTrack, calmTrackdata, melidataFn);
    }

    if (hasCustomMelidata) {
      handleCustomEventTrackMelidata(
        validPathConvention,
        {
          calmViewTrack,
          customViewTrack,
          calmEventMelidata: calmTrackdata,
          customEventMelidata: customMelidata,
        },
        melidataFn,
      );
    }
  }
};

export const handleShowTrackMelidata = (calmTrackdata) => {
  const { melidata: melidataFn, __PRELOADED_STATE__: state = {} } =
    window || {};

  if (melidataFn) {
    const viewTrackData = state.landingConfig?.tracking?.melidata || {};
    const { calmTrack: calmViewTrack } = viewTrackData;

    handleCalmShowTrack(
      calmViewTrack,
      calmTrackdata,
      calmViewTrack?.experiments,
      melidataFn,
    );
  }
};

export const handleTrackGTM = (gtmConfig, gtmViewConfig) => {
  const { reply_data: replyData, ...gtmData } = gtmConfig || {};
  const { dataLayer: gtmDataLayer } = window || {};

  if (gtmData && gtmDataLayer) {
    const gtmEventData = {
      ...(replyData ? { ...gtmViewConfig.dataLayer[0] } : {}),
      event: gtmData.event,
      ...gtmData.additional_data,
    };

    gtmDataLayer.push(gtmEventData);
  }
};
