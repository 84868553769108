import { MELIDATA_DEFAULTS } from '../../utils/constants';

const {
  MODE: { CLEAN_SEND },
  TRACK_TYPE: { VIEW },
} = MELIDATA_DEFAULTS;

export const sendViewTrack = () => {
  try {
    const { melidata: melidataFn, __PRELOADED_STATE__: state } = window || {};

    const customTrack =
      state.landingConfig?.tracking?.melidata?.customTrack || {};

    if (Object.keys(customTrack).length && melidataFn) {
      melidataFn(CLEAN_SEND, VIEW, {
        path: customTrack.path,
        data: {
          ...customTrack.event_data,
        },
        ...(customTrack?.experiments && {
          experiments: customTrack.experiments,
        }),
      });
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
